@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    line-height: 1.6;

    .life_text {
        font-size: 1.6rem;
        font-family: 'Poppins', sans-serif;
        color: #515151;
        line-height: 1.6;
        letter-spacing: 1px;
        width: 25rem;
        margin: 0 auto;
        margin-top: 4rem;

        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: #3e919f;
            margin-top: 1rem;

        }
    }


    .title_section{
        font-size: 3rem;
        font-family: 'Montserrat', sans-serif;
        color: #003373;
        font-weight: 700;
        letter-spacing: 2px;
        text-shadow: #003373;
        position: relative;

        &:after{
            //Linea
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #003373;
            top: 100%;
            left: 0;
            z-index: -1;
        }
    }

    .title_small{
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        color: #003373;
        font-weight: 600;
        text-shadow: #003373;
    }

    .subtitle_section{
        font-size: 1.7rem;
        font-family: 'Noto Serif', serif;
        color: #3b90ad;
        letter-spacing: 2px;
        text-shadow: #003373;
        margin-top: 2rem;
    }

    .standard_text_section{
        font-size: 1.6rem;
        font-family: 'Poppins', sans-serif;
        color: #515151;
        line-height: 1.6;
        letter-spacing: 1px;
    }

    .hero_title {
        font-size: 3rem;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: #003373;
        margin-bottom: 1rem;
    }

    .hero_description {
        font-size: 1.6rem;
        font-family: 'Poppins', sans-serif;
        color: #fff;
        line-height: 1.6;
        letter-spacing: 1px;
        margin-bottom: 2rem;
        max-width: 700px;
        margin: 0 auto;
    }

    .swal2-container{
        z-index: 9999;
    }
}